import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, tap } from 'rxjs';
import { environment } from '../../../../environments/environment';
import {
  CustomerInfo,
  GetRefreshTokenResponse,
  TokenGroup,
} from '../../models/customer-info.types';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(private http: HttpClient) {}

  private userToken?: TokenGroup;

  userModel?: CustomerInfo;
  // register, login, logout

  refreshToken(): Observable<GetRefreshTokenResponse> {
    return this.http
      .post<GetRefreshTokenResponse>(
        environment.baseApiUrl + '/customer/auth/public/refresh/tokens',
        {
          phoneNumber: this.userModel?.phoneNumber,
          refreshToken: this.userToken?.refreshToken,
        },
        {
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
            'x-api-key': environment.customerApiKey,
          }),
        }
      )
      .pipe(
        tap((response) => {
          this.setNewAccessToken(response.data);
        })
      );
  }

  private setNewAccessToken(tokenGroup: TokenGroup): void {
    this.userToken = tokenGroup;
    localStorage.setItem('userToken', JSON.stringify(this.userToken));
  }

  setToken(token: TokenGroup) {
    this.userToken = token;
    localStorage.setItem('userToken', JSON.stringify(token));
  }

  getAccessToken() {
    if (!this.userToken) {
      const token = localStorage.getItem('userToken')
      if (!token) {
        return;
      }
      this.setToken(JSON.parse(token))
    }
    return this.userToken?.accessToken;
  }

  getRefreshToken() {
    return this.userToken?.refreshToken;
  }
}
