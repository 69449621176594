import { Component, HostListener, OnInit } from '@angular/core';
import { VehicleService } from './shared/services/vehicle/vehicle.service';

@Component({
  selector: 'app-root',
  template: '<router-outlet></router-outlet>',
})
export class AppComponent implements OnInit {
  title = 'rental-frontend';

  constructor(private vehicleService: VehicleService) {}

  @HostListener('window:beforeunload', ['$event'])
  beforeUnloadHandler(event: Event): void {
    this.vehicleService.reservationDetails$.subscribe((reservationDetails) => {
      if (reservationDetails) {
        localStorage.setItem('reservation', JSON.stringify(reservationDetails));
      }
    });
  }

  ngOnInit(): void {
    const reservationDetails = localStorage.getItem('reservation');
    if (reservationDetails) {
      this.vehicleService.updateReservation(JSON.parse(reservationDetails));
    }
  }
}
