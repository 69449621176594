import {
  BusinessHour,
  VehicleViewModel,
} from '../../../shared/services/vehicle/vehicle.service.types';

// Define a type for a point with latitude and longitude coordinates
export type Coordinate = {
  lat: number;
  lng: number;
};
export interface HandleFetchVehiclesInNewLocationParams extends Coordinate {
  radius: number;
}

export interface SelectVehicleCardViewModel {
  vehicle: VehicleViewModel;
  dealer: {
    dealerId: string;
    name: string;
    logoUrl: string;
    openingHours: BusinessHour[];
    address: string;
  };
}

export interface DealerMarkerViewModel {
  dealerId: string;
  logoUrl: string;
  minPrice: string;
  maxPrice: string;
  address: string;
  distance: number;
  position: { lat: number; lng: number };
  vehicles: { imageUrl: string }[];
}

// Define the function to calculate the distance between two points
export function haversineDistance(
  coord1: Coordinate,
  coord2: Coordinate
): number {
  // Radius of the Earth in kilometers
  const R = 6371.0;

  // Convert latitude and longitude from degrees to radians
  const lat1 = coord1.lat * (Math.PI / 180);
  const lng1 = coord1.lng * (Math.PI / 180);
  const lat2 = coord2.lat * (Math.PI / 180);
  const lng2 = coord2.lng * (Math.PI / 180);

  // Calculate the differences between the corresponding latitudes and longitudes
  const dlat = lat2 - lat1;
  const dlng = lng2 - lng1;

  // Apply the Haversine formula
  const a =
    Math.sin(dlat / 2) * Math.sin(dlat / 2) +
    Math.cos(lat1) * Math.cos(lat2) * Math.sin(dlng / 2) * Math.sin(dlng / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

  // Calculate the distance in kilometers
  const distance = R * c;
  return distance;
}

export function getDateTimeCombined(date: string, time: string): Date {
  // Concatenate date and time strings with a space separator
  const combinedDateTimeString = `${date} ${time}`;
  // Convert to a Date object
  const dateTime = new Date(combinedDateTimeString);

  return dateTime;
}

export function getFormattedDateTime(date: string, time: string): string {
  const time24h = convertTo24Hour(time);
  const dateTimeString = `${date} ${time24h}`;
  const dateTime = new Date(dateTimeString);
  return dateTime.toISOString();
}

function convertTo24Hour(time12h: string): string {
  const [time, modifier] = time12h.split(' ');
  let [hours, minutes] = time.split(':');

  if (hours === '12') {
    hours = '00';
  }

  if (modifier === 'PM') {
    hours = (parseInt(hours, 10) + 12).toString();
  }

  return `${hours}:${minutes}`;
}

export function getTotalPrice(
  dailyRate: number,
  weeklyRate: number,
  monthlyRate: number,
  pickupDateTime: string,
  returnDateTime: string
): number {
  const days =
    (new Date(returnDateTime).getTime() - new Date(pickupDateTime).getTime()) / (1000 * 3600 * 24);
  let weeks = Math.floor(days / 7);
  const daysLeft = Math.floor(days % 7);
  const months = Math.floor(weeks / 4);

  if (months > 0) {
    weeks = weeks % 4
    return months * monthlyRate + weeks * weeklyRate + daysLeft * dailyRate;
  }
  return weeks * weeklyRate + daysLeft * dailyRate;
}
