import { Environment } from './environment.types';

export const environment: Environment = {
  env: 'development',
  dealerBaseApiUrl: 'https://dev-api.cxztrac.com',
  dealerApiKey: 'tEmD9RVvL9aWkzGtnBu287hoiQQMRgfL1K40OsQs',
  dealerVehicleApiKey: 'DUhGdv9ysc5rZCg7huHLM2lV0A8Lfi453lXLuAlN',
  baseApiUrl: 'https://api.dev-rental.connexionmobility.com',
  customerApiKey: 'IB85kPfvpL2EGfRPia2yJ9iacP53axoW5b5amzRR',
  vehicleApiKey: 'xO8m0xKxIo9eJ1znaOcTu8WjwRLUpV7U3wLLv7nX',
  paymentApiKey: 'ohGSOw2LjK2ePsRJoL5Hn1ZhWxtNPeAR2I4sC1Y2',
  googleMapApiKey: 'AIzaSyBfH-ptNv5q4KSKj9WXXVQ9VHpxbeh5M4U',
  googleMapEmbedApiUrl: 'https://maps.googleapis.com/maps/api/staticmap',
  captchaDisabled: 'true',
  captchaSiteKey: '6LeHViIpAAAAAFYpZm7CfqWzyA2nhrljHB8w5aB5',
  workingHours: {
    open: '09:00',
    close: '18:00',
  },
  salesTax: '0',
  platformFees: '10',
  expressCheckInBaseUrl: 'https://dev-express.connexionmobility.com',
  enableExressCheckIn: 'true',
};