import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, mergeMap, throwError } from 'rxjs';
import { AuthService } from '../../shared/services/auth/auth-service.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    const jwt = this.authService.getAccessToken();

    if (jwt !== '') {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${jwt}`,
        },
      });
    }

    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        if (
          error instanceof HttpErrorResponse &&
          !request.url.includes('/customer/auth/public/otp/verify') &&
          !request.url.includes('/customer/auth/public/initiate') &&
          error.status === 401
        ) {
          return this.authService.refreshToken().pipe(
            mergeMap((token): any => {
              request = request.clone({
                setHeaders: {
                  Authorization: `Bearer ${token['data']['accessToken']}`,
                },
              });
            })
          );
        }
        return throwError(() => error);
      })
    ) as Observable<any>;
  }
}
